.all {
  display: flex;
}

.desktopOnly {
  display: none;

  @media (min-width: 1280px) {
    display: flex;
  }
}

.mobileOnly {
  display: flex;

  @media (min-width: 1280px) {
    display: none;
  }
}


