@font-face {
  font-family: "Open Sans";
  font-weight: 300;
  font-style: normal;
  src: url('OpenSans-Light.ttf') format('truetype');
}
@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  font-style: normal;
  src: url('OpenSans-Regular.ttf') format('truetype');
}
@font-face {
  font-family: "Open Sans";
  font-weight: 500;
  font-style: normal;
  src: url('OpenSans-Medium.ttf') format('truetype');
}
@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-style: normal;
  src: url('OpenSans-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  font-style: normal;
  src: url('OpenSans-Bold.ttf') format('truetype');
}
@font-face {
  font-family: "Open Sans";
  font-weight: 800;
  font-style: normal;
  src: url('OpenSans-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: "Open Sans";
  font-weight: 300;
  font-style: italic;
  src: url('OpenSans-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Open Sans";
  font-weight: 400;
  font-style: italic;
  src: url('OpenSans-Italic.ttf') format('truetype');
}
@font-face {
  font-family: "Open Sans";
  font-weight: 500;
  font-style: italic;
  src: url('OpenSans-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Open Sans";
  font-weight: 600;
  font-style: italic;
  src: url('OpenSans-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Open Sans";
  font-weight: 700;
  font-style: italic;
  src: url('OpenSans-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Open Sans";
  font-weight: 800;
  font-style: italic;
  src: url('OpenSans-ExtraBoldItalic.ttf') format('truetype');
}