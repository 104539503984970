@font-face {
  font-family: 'Courier New';
  font-style: normal;
  font-weight: normal;
  src: url('cour.ttf') format('truetype');
}

@font-face {
  font-family: 'Courier New';
  font-style: normal;
  font-weight: bold;
  src: url('courbd.ttf') format('truetype');
}

@font-face {
  font-family: 'Courier New';
  font-style: italic;
  font-weight: bold;
  src: url('courbi.ttf') format('truetype');
}

@font-face {
  font-family: 'Courier New';
  font-style: italic;
  font-weight: normal;
  src: url('couri.ttf') format('truetype');
}
