@font-face {
  font-family: "Oswald";
  font-weight: 200;
  font-style: normal;
  src: url('Oswald-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: "Oswald";
  font-weight: 300;
  font-style: normal;
  src: url('Oswald-Light.ttf') format('truetype');
}
@font-face {
  font-family: "Oswald";
  font-weight: 400;
  font-style: normal;
  src: url('Oswald-Regular.ttf') format('truetype');
}
@font-face {
  font-family: "Oswald";
  font-weight: 500;
  font-style: normal;
  src: url('Oswald-Medium.ttf') format('truetype');
}
@font-face {
  font-family: "Oswald";
  font-weight: 600;
  font-style: normal;
  src: url('Oswald-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: "Oswald";
  font-weight: 700;
  font-style: normal;
  src: url('Oswald-Bold.ttf') format('truetype');
}