@font-face {
  font-family: 'Times New Roman';
  src: url('times.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Times New Roman';
  src: url('timesbd.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Times New Roman';
  src: url('timesbi.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'Times New Roman';
  src: url('timesi.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}
