@font-face {
  font-family: Arial;
  src: url('arial.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: Arial;
  src: url('arialbd.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: Arial;
  src: url('arialbi.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: Arial;
  src: url('ariali.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}
