@font-face {
  font-family: 'Verdana';
  src: url('verdana.ttf') format('truetype');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'Verdana';
  src: url('verdanab.ttf') format('truetype');
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'Verdana';
  src: url('verdanaz.ttf') format('truetype');
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: 'Verdana';
  src: url('verdanai.ttf') format('truetype');
  font-style: italic;
  font-weight: normal;
}
