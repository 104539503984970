@font-face {
  font-family: "Ubuntu";
  font-weight: 300;
  font-style: normal;
  src: url('Ubuntu-Light.ttf') format('truetype');
}
@font-face {
  font-family: "Ubuntu";
  font-weight: 400;
  font-style: normal;
  src: url('Ubuntu-Regular.ttf') format('truetype');
}
@font-face {
  font-family: "Ubuntu";
  font-weight: 500;
  font-style: normal;
  src: url('Ubuntu-Medium.ttf') format('truetype');
}
@font-face {
  font-family: "Ubuntu";
  font-weight: 700;
  font-style: normal;
  src: url('Ubuntu-Bold.ttf') format('truetype');
}
@font-face {
  font-family: "Ubuntu";
  font-weight: 300;
  font-style: italic;
  src: url('Ubuntu-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Ubuntu";
  font-weight: 400;
  font-style: italic;
  src: url('Ubuntu-Italic.ttf') format('truetype');
}
@font-face {
  font-family: "Ubuntu";
  font-weight: 500;
  font-style: italic;
  src: url('Ubuntu-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Ubuntu";
  font-weight: 700;
  font-style: italic;
  src: url('Ubuntu-BoldItalic.ttf') format('truetype');
}