@font-face {
  font-family: "Poppins";
  font-weight: 100;
  font-style: normal;
  src: url('Poppins-Thin.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 200;
  font-style: normal;
  src: url('Poppins-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 300;
  font-style: normal;
  src: url('Poppins-Light.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: normal;
  src: url('Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: normal;
  src: url('Poppins-Medium.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: normal;
  src: url('Poppins-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: normal;
  src: url('Poppins-Bold.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 800;
  font-style: normal;
  src: url('Poppins-ExtraBold.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 900;
  font-style: normal;
  src: url('Poppins-Black.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 100;
  font-style: italic;
  src: url('Poppins-ThinItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 200;
  font-style: italic;
  src: url('Poppins-ExtraLightItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 300;
  font-style: italic;
  src: url('Poppins-LightItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 400;
  font-style: italic;
  src: url('Poppins-Italic.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 500;
  font-style: italic;
  src: url('Poppins-MediumItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 600;
  font-style: italic;
  src: url('Poppins-SemiBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 700;
  font-style: italic;
  src: url('Poppins-BoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 800;
  font-style: italic;
  src: url('Poppins-ExtraBoldItalic.ttf') format('truetype');
}
@font-face {
  font-family: "Poppins";
  font-weight: 900;
  font-style: italic;
  src: url('Poppins-BlackItalic.ttf') format('truetype');
}